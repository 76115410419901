.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg {
  width: 100%;
  height: 100svh;
  background-position: center;
  background-color: #222;
  background-image: url("../../assets/login_bg_color.jpeg");
}

@property --wipe-position {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 50%;
}

@keyframes moveGradient {
  0% {
    --wipe-position: 60%;
  }
  100% {
    --wipe-position: calc((var(--gradient-length)) * 0.5);
  }
}

.bg-overlay {
  position: absolute;
  width: 100%;
  height: 100svh;
  background-position: center;
  background-color: #222;
  background-image: url("../../assets/login_bg_bw.jpeg");

  --wipe-position: 100%;
  --gradient-length: 50%;
  mask-image: linear-gradient(
    to bottom right,
    black var(--wipe-position),
    transparent calc(var(--wipe-position) + var(--gradient-length)),
    transparent
  );
  animation: 10s moveGradient infinite;
  animation-direction: alternate;
}

.loading-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 300px;
  max-width: 500px;
  width: 25%;
  top: 50%;
  margin: auto;
  position: relative;
  transform: translate(0, -50%);
}


.dev-watermark {
  position: absolute;
  top: 0;
  right: 50px;
  width: 50%;
  z-index: 1000;
  font-size: 30px;
  user-select: none;
  pointer-events: none;
  color: red;
  text-align: right;
  align-content: end;
  padding: 1rem;
}

@keyframes blurAnimationNavbar {
  0% {
    opacity: 0;
    filter: blur(0px); /* No blur */
  }
  50% {
    filter: blur(75px); /* Full blur */
  }
  75% {
    filter: blur(45px); /* Full blur */
  }
  100% {
    opacity: 0.75;
    filter: blur(30px); /* Full blur */
  }
}
