.bg {
  width: 100%;
  height: 100svh;
  background-position: center;
  background-color: #222;
  background-image: url("../../assets/login_bg_color.jpeg");
  display: flex;
  justify-content: center;
}

@property --wipe-position {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 50%;
}

@keyframes moveGradient {
  0% {
    --wipe-position: 60%;
  }
  100% {
    --wipe-position: calc((var(--gradient-length)) * 0.5);
  }
}

.bg-overlay {
  position: absolute;
  width: 100%;
  height: 100svh;
  background-position: center;
  background-color: #222;
  background-image: url("../../assets/login_bg_bw.jpeg");

  --wipe-position: 100%;
  --gradient-length: 50%;
  mask-image: linear-gradient(
    to bottom right,
    black var(--wipe-position),
    transparent calc(var(--wipe-position) + var(--gradient-length)),
    transparent
  );
  animation: 10s moveGradient infinite;
  animation-direction: alternate;
}

.login-div {
  text-align: center;
  min-height: 370px;
  width: 450px;
  top: 50%;
  margin: auto;
  background-color: #222222;
  position: relative;
  transform: translate(0, -50%);
  border-radius: 16px;
  filter: drop-shadow(0px 10px 20px #00000055);
}


@keyframes blurAnimation {
  0% {
    opacity: 0;
    filter: blur(0px); /* No blur */
  }
  50% {
    opacity: 0.3;
    filter: blur(45px); /* Full blur */
  }
  75% {
    opacity: 0.6;
    filter: blur(25px); /* Full blur */
  }
  100% {
    opacity: 0.95;
    filter: blur(30px); /* Full blur */
  }
}

@keyframes blurAnimationBG {
  0% {
    opacity: 0;
    filter: blur(0px); /* No blur */
  }
  50% {
    filter: blur(75px); /* Full blur */
  }
  75% {
    filter: blur(45px); /* Full blur */
  }
  100% {
    opacity: 0.50;
    filter: blur(80px); /* Full blur */
  }
}