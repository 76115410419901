.basicmap {
  height: 100svh;
  width: 100%;
  background: var(--ol-background-color);
  margin-bottom: 0px;
  background-color: #222;
  background-image: linear-gradient(rgba(43, 43, 43, .7) .1em, transparent .1em), linear-gradient(90deg, rgba(43, 43, 43, .7) .1em, transparent .1em);
  background-size: 6em 6em;
}


.ol-popup {
  position: absolute;
  background-color: var(--mui-backgroundcolor);
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  padding: 8px;
  padding-top: 4px;
  border-radius: 12px;
  bottom: 16px;
  transform: translateX(-50%);
  filter: drop-shadow(0px 2px 5px #00000022);
}

.ol-popup-inner {
  max-width: 900px;
  min-height: 150px;
  overflow: hidden;
}

.ol-popup-header {
  display: flex;
  height: 34px;
}

.ol-popup-content {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.ol-popup:after {
  position: absolute;
  bottom: -16px;
  left: calc(50%);
  transform: translateX(-50%);
  content: "";
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid var(--mui-backgroundcolor);
}





/*.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: eee;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #eee;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}*/

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: "✖";
}